html,
body {
  overscroll-behavior-x: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.message-overflow {
  overflow: visible !important;
}

.textinput-composer {
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  border: 0px solid black;
  box-sizing: border-box;
  font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  resize: none;
  flex: 1 1 0%;
}

.msgImg {
  object-fit: contain;
  width: 100%;
  border-radius: 11px 11px 0px 0px;
}

/** to block safari behavior on asking to fill passowrd**/
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.password-field {
  -webkit-text-security: circle;
}

.text-field {
  -webkit-text-security: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
}

/* * {
  scrollbar-width: thin
}

::-webkit-scrollbar {
  width: 15px
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 20px
} */

div[data-testid="GC_WRAPPER"] {
  width: 100%;
}

#root,
body,
html {
  height: 100%;
  overflow: hidden;
}

.RoomSwipable .MuiBox-root {
  padding: 0;
}

.sb-tab-link {
  text-transform: none;
  color: inherit;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

#image-carousel .react-swipeable-view-container {
  height: 100%;
}

#image-carousel .react-swipeable-view-container div {
  overflow: hidden !important;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 8px;
}

.gallery-container .previewImage,
.gallery-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-container div:only-child,
.gallery-container > img:only-child {
  width: 200px;
  object-fit: contain;
}
@media (max-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

.local-video {
  width: 100%;
  height: calc(100% - 47px);
  background-color: black;
  position: absolute;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.local-video.minimized {
  height: inherit;
  border: 1px solid #000;
  width: 240px;
  bottom: 47px;
}

@media (max-width: 600px) {
  .local-video.minimized {
    height: inherit;
    border: 1px solid #000;
    width: 120px;
    bottom: 47px;
  }
}


